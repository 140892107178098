<template>
  <div v-if="!loading">
    <h3>Demographic Selector</h3>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :md="16"
        :lg="18"
      >
        <el-tabs>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="geographicSelectedCount"
                :hidden="!geographicSelectedCount"
              >
                Geographic
              </el-badge>
            </span>
            <el-tabs class="no-pad">
              <el-tab-pane>
                <span slot="label">
                  States
                  <!--<el-checkbox-->
                  <!--v-show="selected.geographic.states.length <= 5"-->
                  <!--v-model="all.states[0]"-->
                  <!--class="select-all"-->
                  <!--:indeterminate="indeterminate('states', null)"-->
                  <!--@change="handleCheckAllChange('states', null, $event)"-->
                  <!--/>-->
                </span>
                <el-checkbox-group
                  v-model="selected.geographic.states"
                  @change="resetCount"
                >
                  <div
                    v-for="(chunk, index) of selectors.states"
                    :key="index"
                    class="section"
                  >
                    <div
                      v-for="state of chunk"
                      :key="state.abbreviation"
                    >
                      <el-checkbox
                        :label="state.abbreviation"
                        :disabled="countPending || saving || stateLimit(state.abbreviation)"
                      >
                        {{ state.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">
                  MSA
                  <!--<el-checkbox-->
                  <!--v-model="all.msa[0]"-->
                  <!--class="select-all"-->
                  <!--:indeterminate="indeterminate('msa')"-->
                  <!--@change="handleCheckAllChange('msa', null, $event)"-->
                  <!--/>-->
                </span>
                <el-checkbox-group
                  v-model="selected.geographic.msa"
                  @change="resetCount"
                >
                  <div
                    v-for="(chunk, index) of selectors.msa"
                    :key="index"
                    class="section"
                  >
                    <div
                      v-for="m of chunk"
                      :key="m.value"
                    >
                      <el-checkbox
                        :label="m.value"
                        :disabled="countPending || saving || msaLimit(m.value)"
                      >
                        {{ m.label }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-tab-pane>
              <el-tab-pane label="Zipcodes">
                <div class="form-control">
                  <label>Enter a list of 5-digit zipcodes</label>
                  <el-input
                    v-model="zipcodes"
                    placeholder="01083, 11010, 90210"
                    type="textarea"
                    :rows="10"
                    :disabled="countPending || saving"
                  />
                  <p>
                    Zipcodes may be entered one per line or comma separated. Each contiguous block of 5 digits will be treated as a distinct zipcode entry.
                  </p>
                </div>
              </el-tab-pane>

              <el-tab-pane
                label="Postal Carrier Routes"
              >
                <div class="form-control">
                  <label>Enter a list of 9-digit postal carrier routes</label>
                  <el-input
                    v-model="postal_routes"
                    placeholder="90210C003, 90210C005, 90210C007"
                    type="textarea"
                    :rows="10"
                    :disabled="countPending || saving"
                  />
                  <p>
                    Postal carrier route codes are 9 digits:<br>
                    5 numbers for the ZIP Code, one letter for the carrier route type, and 3 numbers for the carrier route code &ndash; for example, "92019C005" or "84604R009"
                  </p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.demographic.length"
                :hidden="!selected.demographic.length"
              >
                Demographic
              </el-badge>
            </span>
            <div class="section">
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[0].title }}
                  <el-checkbox
                    v-model="all.demographic[0]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 0)"
                    @change="handleCheckAllChange('demographic', 0, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[0].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[1].title }}
                  <el-checkbox
                    v-model="all.demographic[1]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 1)"
                    @change="handleCheckAllChange('demographic', 1, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[1].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[2].title }}
                  <el-checkbox
                    v-model="all.demographic[2]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 2)"
                    @change="handleCheckAllChange('demographic', 2, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[2].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[5].title }}
                  <el-checkbox
                    v-model="all.demographic[5]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 5)"
                    @change="handleCheckAllChange('demographic', 5, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[5].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[3].title }}
                  <el-checkbox
                    v-model="all.demographic[3]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 3)"
                    @change="handleCheckAllChange('demographic', 3, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[3].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[9].title }}
                  <el-checkbox
                    v-model="all.demographic[9]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 9)"
                    @change="handleCheckAllChange('demographic', 9, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[9].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[7].title }}
                  <el-checkbox
                    v-model="all.demographic[7]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 7)"
                    @change="handleCheckAllChange('demographic', 7, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[7].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[4].title }}
                  <el-checkbox
                    v-model="all.demographic[4]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 4)"
                    @change="handleCheckAllChange('demographic', 4, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[4].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[6].title }}
                  <el-checkbox
                    v-model="all.demographic[6]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 6)"
                    @change="handleCheckAllChange('demographic', 6, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[6].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.demographicSegments[10].title }}
                  <el-checkbox
                    v-model="all.demographic[10]"
                    class="select-all"
                    :indeterminate="indeterminate('demographic', 10)"
                    @change="handleCheckAllChange('demographic', 10, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.demographic"
                  @change="resetCount"
                >
                  <div
                    v-for="demographic of selectors.demographicSegments[10].children"
                    :key="demographic.value"
                  >
                    <el-checkbox
                      :label="demographic.value"
                      :disabled="countPending || saving"
                    >
                      {{ demographic.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.interest.length"
                :hidden="!selected.interest.length"
              >
                Interests
              </el-badge>
            </span>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.interests[0].title }}
                <el-checkbox
                  v-model="all.interest[0]"
                  class="select-all"
                  :indeterminate="indeterminate('interest', 0)"
                  @change="handleCheckAllChange('interest', 0, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.interest"
                @change="resetCount"
              >
                <div
                  v-for="interest of selectors.interests[0].children"
                  :key="interest.value"
                >
                  <el-checkbox
                    :label="interest.value"
                    :disabled="countPending || saving"
                  >
                    {{ interest.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[1].title }}
                  <el-checkbox
                    v-model="all.interest[1]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 1)"
                    @change="handleCheckAllChange('interest', 1, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[1].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[2].title }}
                  <el-checkbox
                    v-model="all.interest[2]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 2)"
                    @change="handleCheckAllChange('interest', 2, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[2].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.interests[3].title }}
                <el-checkbox
                  v-model="all.interest[3]"
                  class="select-all"
                  :indeterminate="indeterminate('interest', 3)"
                  @change="handleCheckAllChange('interest', 3, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.interest"
                @change="resetCount"
              >
                <div
                  v-for="interest of selectors.interests[3].children"
                  :key="interest.value"
                >
                  <el-checkbox
                    :label="interest.value"
                    :disabled="countPending || saving"
                  >
                    {{ interest.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[4].title }}
                  <el-checkbox
                    v-model="all.interest[4]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 4)"
                    @change="handleCheckAllChange('interest', 4, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[4].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[5].title }}
                  <el-checkbox
                    v-model="all.interest[5]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 5)"
                    @change="handleCheckAllChange('interest', 5, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[5].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.interests[6].title }}
                <el-checkbox
                  v-model="all.interest[6]"
                  class="select-all"
                  :indeterminate="indeterminate('interest', 6)"
                  @change="handleCheckAllChange('interest', 6, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.interest"
                @change="resetCount"
              >
                <div
                  v-for="interest of selectors.interests[6].children"
                  :key="interest.value"
                >
                  <el-checkbox
                    :label="interest.value"
                    :disabled="countPending || saving"
                  >
                    {{ interest.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[7].title }}
                  <el-checkbox
                    v-model="all.interest[7]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 7)"
                    @change="handleCheckAllChange('interest', 7, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[7].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.interests[8].title }}
                <el-checkbox
                  v-model="all.interest[8]"
                  class="select-all"
                  :indeterminate="indeterminate('interest', 8)"
                  @change="handleCheckAllChange('interest', 8, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.interest"
                @change="resetCount"
              >
                <div
                  v-for="interest of selectors.interests[8].children"
                  :key="interest.value"
                >
                  <el-checkbox
                    :label="interest.value"
                    :disabled="countPending || saving"
                  >
                    {{ interest.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.interests[9].title }}
                  <el-checkbox
                    v-model="all.interest[9]"
                    class="select-all"
                    :indeterminate="indeterminate('interest', 9)"
                    @change="handleCheckAllChange('interest', 9, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.interest"
                  @change="resetCount"
                >
                  <div
                    v-for="interest of selectors.interests[9].children"
                    :key="interest.value"
                  >
                    <el-checkbox
                      :label="interest.value"
                      :disabled="countPending || saving"
                    >
                      {{ interest.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.purchaseIntenders.length"
                :hidden="!selected.purchaseIntenders.length"
              >
                Purchase Intenders
              </el-badge>
            </span>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.purchaseIntenders[0].title }}
                <el-checkbox
                  v-model="all.purchaseIntenders[0]"
                  class="select-all"
                  :indeterminate="indeterminate('purchaseIntenders', 0)"
                  @change="handleCheckAllChange('purchaseIntenders', 0, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.purchaseIntenders"
                @change="resetCount"
              >
                <div
                  v-for="purchaseIntenders of selectors.purchaseIntenders[0].children"
                  :key="purchaseIntenders.value"
                >
                  <el-checkbox
                    :label="purchaseIntenders.value"
                    :disabled="countPending || saving"
                  >
                    {{ purchaseIntenders.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.purchaseIntenders[1].title }}
                  <el-checkbox
                    v-model="all.purchaseIntenders[1]"
                    class="select-all"
                    :indeterminate="indeterminate('purchaseIntenders', 1)"
                    @change="handleCheckAllChange('purchaseIntenders', 1, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.purchaseIntenders"
                  @change="resetCount"
                >
                  <div
                    v-for="purchaseIntenders of selectors.purchaseIntenders[1].children"
                    :key="purchaseIntenders.value"
                  >
                    <el-checkbox
                      :label="purchaseIntenders.value"
                      :disabled="countPending || saving"
                    >
                      {{ purchaseIntenders.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div>
                <h5 class="section-title">
                  {{ selectors.purchaseIntenders[2].title }}
                  <el-checkbox
                    v-model="all.purchaseIntenders[2]"
                    class="select-all"
                    :indeterminate="indeterminate('purchaseIntenders', 2)"
                    @change="handleCheckAllChange('purchaseIntenders', 2, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.purchaseIntenders"
                  @change="resetCount"
                >
                  <div
                    v-for="purchaseIntenders of selectors.purchaseIntenders[2].children"
                    :key="purchaseIntenders.value"
                  >
                    <el-checkbox
                      :label="purchaseIntenders.value"
                      :disabled="countPending || saving"
                    >
                      {{ purchaseIntenders.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="section">
              <h5 class="section-title">
                {{ selectors.purchaseIntenders[3].title }}
                <el-checkbox
                  v-model="all.purchaseIntenders[3]"
                  class="select-all"
                  :indeterminate="indeterminate('purchaseIntenders', 3)"
                  @change="handleCheckAllChange('purchaseIntenders', 3, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.purchaseIntenders"
                @change="resetCount"
              >
                <div
                  v-for="purchaseIntenders of selectors.purchaseIntenders[3].children"
                  :key="purchaseIntenders.value"
                >
                  <el-checkbox
                    :label="purchaseIntenders.value"
                    :disabled="countPending || saving"
                  >
                    {{ purchaseIntenders.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <div>
                <h5 class="section-title">
                  {{ selectors.purchaseIntenders[4].title }}
                  <el-checkbox
                    v-model="all.purchaseIntenders[4]"
                    class="select-all"
                    :indeterminate="indeterminate('purchaseIntenders', 4)"
                    @change="handleCheckAllChange('purchaseIntenders', 4, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.purchaseIntenders"
                  @change="resetCount"
                >
                  <div
                    v-for="purchaseIntenders of selectors.purchaseIntenders[4].children"
                    :key="purchaseIntenders.value"
                  >
                    <el-checkbox
                      :label="purchaseIntenders.value"
                      :disabled="countPending || saving"
                    >
                      {{ purchaseIntenders.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.financial.length"
                :hidden="!selected.financial.length"
              >
                Financial
              </el-badge>
            </span>
            <div
              v-for="(section, index) of selectors.financials"
              :key="index"
              class="section"
            >
              <h5 class="section-title">
                {{ section.title }}
                <el-checkbox
                  v-model="all.financial[index]"
                  class="select-all"
                  :indeterminate="indeterminate('financial', index)"
                  @change="handleCheckAllChange('financial', index, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.financial"
                @change="resetCount"
              >
                <div
                  v-for="financial of section.children"
                  :key="financial.value"
                >
                  <el-checkbox
                    :label="financial.value"
                    :disabled="countPending || saving"
                  >
                    {{ financial.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.ethnicities.length"
                :hidden="!selected.ethnicities.length"
              >
                Ethnicity
              </el-badge>
            </span>
            <div v-if="selectors.ethnicities.length">
              <div class="section">
                <h5 class="section-title">
                  {{ selectors.ethnicities[0].title }}
                  <el-checkbox
                    v-model="all.ethnicities[0]"
                    class="select-all"
                    :indeterminate="indeterminate('ethnicities', 0)"
                    @change="handleCheckAllChange('ethnicities', 0, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.ethnicities"
                  @change="resetCount"
                >
                  <div
                    v-for="ethnicity of selectors.ethnicities[0].children"
                    :key="ethnicity.value"
                  >
                    <el-checkbox
                      :label="ethnicity.value"
                      :disabled="countPending || saving"
                    >
                      {{ ethnicity.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <div class="section">
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[1].title }}
                    <el-checkbox
                      v-model="all.ethnicities[1]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 1)"
                      @change="handleCheckAllChange('ethnicities', 1, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[1].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[2].title }}
                    <el-checkbox
                      v-model="all.ethnicities[2]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 2)"
                      @change="handleCheckAllChange('ethnicities', 2, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[2].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[3].title }}
                    <el-checkbox
                      v-model="all.ethnicities[3]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 3)"
                      @change="handleCheckAllChange('ethnicities', 3, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[3].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[6].title }}
                    <el-checkbox
                      v-model="all.ethnicities[6]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 6)"
                      @change="handleCheckAllChange('ethnicities', 6, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[6].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[8].title }}
                    <el-checkbox
                      v-model="all.ethnicities[8]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 8)"
                      @change="handleCheckAllChange('ethnicities', 8, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[8].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>

              <div class="section">
                <h5 class="section-title">
                  {{ selectors.ethnicities[4].title }}
                  <el-checkbox
                    v-model="all.ethnicities[4]"
                    class="select-all"
                    :indeterminate="indeterminate('ethnicities', 4)"
                    @change="handleCheckAllChange('ethnicities', 4, $event)"
                  />
                </h5>
                <el-checkbox-group
                  v-model="selected.ethnicities"
                  @change="resetCount"
                >
                  <div
                    v-for="ethnicity of selectors.ethnicities[4].children"
                    :key="ethnicity.value"
                  >
                    <el-checkbox
                      :label="ethnicity.value"
                      :disabled="countPending || saving"
                    >
                      {{ ethnicity.label }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <div class="section">
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[5].title }}
                    <el-checkbox
                      v-model="all.ethnicities[5]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 5)"
                      @change="handleCheckAllChange('ethnicities', 5, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[5].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[7].title }}
                    <el-checkbox
                      v-model="all.ethnicities[7]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 7)"
                      @change="handleCheckAllChange('ethnicities', 7, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[7].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
                <div>
                  <h5 class="section-title">
                    {{ selectors.ethnicities[9].title }}
                    <el-checkbox
                      v-model="all.ethnicities[9]"
                      class="select-all"
                      :indeterminate="indeterminate('ethnicities', 9)"
                      @change="handleCheckAllChange('ethnicities', 9, $event)"
                    />
                  </h5>
                  <el-checkbox-group
                    v-model="selected.ethnicities"
                    @change="resetCount"
                  >
                    <div
                      v-for="ethnicity of selectors.ethnicities[9].children"
                      :key="ethnicity.value"
                    >
                      <el-checkbox
                        :label="ethnicity.value"
                        :disabled="countPending || saving"
                      >
                        {{ ethnicity.label }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-badge
                :value="selected.charity.length"
                :hidden="!selected.charity.length"
              >
                Charity
              </el-badge>
            </span>
            <div class="section">
              <h5 class="section-title">
                Charity
                <el-checkbox
                  v-model="all.charity[0]"
                  class="select-all"
                  :indeterminate="indeterminate('charity', null)"
                  @change="handleCheckAllChange('charity', null, $event)"
                />
              </h5>
              <el-checkbox-group
                v-model="selected.charity"
                @change="resetCount"
              >
                <div
                  v-for="charity of filteredCharities"
                  :key="charity.value"
                >
                  <el-checkbox
                    :label="charity.value"
                    :disabled="countPending || saving"
                  >
                    {{ charity.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="hasPermission('demographic_political')"
          >
            <span slot="label">
              <el-badge
                :value="selected.political.length"
                :hidden="!selected.political.length"
              >
                Political
              </el-badge>
            </span>
            <div
              v-for="(section, index) of selectors.political"
              :key="`political-${index}`"
              class="section political"
            >
              <h5 class="section-title">
                {{ selectors.political[index].title }}
                <help-tip class="help">
                  <p>
                    {{ selectors.political[index].tooltip }}
                  </p>
                </help-tip>
              </h5>
              <el-checkbox-group
                v-model="selected.political"
                @change="resetCount"
              >
                <div
                  v-for="political of selectors.political[index].children"
                  :key="political.value"
                  class="political"
                >
                  <el-checkbox
                    :label="political.value"
                    :disabled="countPending || saving"
                    @change="function (val) { if (!val) { politicalRanges[political.value] = [0,0] }}"
                  >
                    {{ political.label }}
                  </el-checkbox>
                  <el-slider
                    v-model="politicalRanges[political.value]"
                    range
                    :min="political.range[0]"
                    :max="political.range[1]"
                    :step="political.step"
                    :disabled="countPending || saving || !selected.political.includes(political.value)"
                  />
                </div>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="hasPermission('demographic_political')"
          >
            <span slot="label">
              <el-badge
                :value="selected.politicalDistrict.length"
                :hidden="!selected.politicalDistrict.length"
              >
                Political Districts
              </el-badge>
            </span>
            <div class="section political">
              <el-table
                :data="selected.politicalDistrict"
                class="political-districts"
              >
                <el-table-column
                  label="State"
                  width="140"
                >
                  <template slot-scope="{ row }">
                    {{ row.state | stateName }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Federal Congressional District"
                  prop="cong_dist"
                  width="160"
                />
                <el-table-column
                  label="State Senate District"
                  prop="st_up_hous"
                  width="160"
                />
                <el-table-column
                  label="State Assembly or Legistlative District"
                  prop="st_lo_hous"
                  width="170"
                />
                <el-table-column
                  width="100"
                >
                  <template slot-scope="{ $index }">
                    <el-button
                      class="remove"
                      size="small"
                      type="danger"
                      icon="fa fa-trash"
                      :disabled="countPending || saving"
                      @click="removeDistrict($index)"
                    />
                  </template>
                </el-table-column>
              </el-table>

              <el-table
                :data="[{}]"
                class="political-districts"
              >
                <el-table-column
                  width="140"
                >
                  <el-select
                    v-model="district.state"
                    placeholder="Select"
                    clearable
                    :disabled="countPending || saving"
                    @change="district.cong_dist = null; district.st_lo_hous = null; district.st_up_hous = null;"
                  >
                    <el-option
                      v-for="state in selectors.politicalDistricts"
                      :key="state.abbreviation"
                      :label="state.name"
                      :value="state.abbreviation"
                    />
                  </el-select>
                </el-table-column>
                <el-table-column
                  width="160"
                >
                  <el-select
                    v-if="district.state"
                    v-model="district.cong_dist"
                    clearable
                    :disabled="countPending || saving"
                  >
                    <el-option
                      v-for="i in getDistrictRange('cong_dist', district.state)"
                      :key="`${district.state}-cong-dist-${i}`"
                      :value="i"
                    />
                  </el-select>
                </el-table-column>
                <el-table-column
                  width="160"
                >
                  <el-select
                    v-if="district.state"
                    v-model="district.st_up_hous"
                    clearable
                    :disabled="countPending || saving"
                  >
                    <el-option
                      v-for="i in getDistrictRange('st_up_hous', district.state)"
                      :key="`${district.state}-st-up-hous-${i}`"
                      :value="i"
                    />
                  </el-select>
                </el-table-column>
                <el-table-column
                  width="170"
                >
                  <el-select
                    v-if="district.state && district.state !== 'NE'"
                    v-model="district.st_lo_hous"
                    clearable
                    :disabled="countPending || saving"
                  >
                    <el-option
                      v-for="i in getDistrictRange('st_lo_hous', district.state)"
                      :key="`${district.state}-st-lo-hous-${i}`"
                      :value="i"
                    />
                  </el-select>
                </el-table-column>
                <el-table-column
                  width="100"
                >
                  <el-button
                    :disabled="(!district.cong_dist && !district.st_lo_hous && !district.st_up_hous) || countPending || saving"
                    @click="addDistrict()"
                  >
                    <i class="fa fa-plus" />
                    Add
                  </el-button>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="hasPermission('demographic_entertainment')">
            <span slot="label">
              <el-badge
                :value="selected.entertainment.music.artists.length + selected.entertainment.music.genres.length"
                :hidden="!selected.entertainment.music.artists.length && !selected.entertainment.music.genres.length"
              >
                Entertainment
              </el-badge>
            </span>
            <el-tabs class="no-pad">
              <el-tab-pane>
                <span slot="label">
                  <el-badge
                    :value="selected.entertainment.music.artists.length + selected.entertainment.music.genres.length"
                    :hidden="!selected.entertainment.music.artists.length && !selected.entertainment.music.genres.length
                    "
                  >
                    Music
                  </el-badge>
                </span>
                <div class="section">
                  <h5 class="section-title">
                    Genres
                  </h5>

                  <el-select
                    v-model="selected.entertainment.music.genres"
                    filterable
                    multiple
                    placeholder="Select Genres"
                    style="width: 240px"
                  >
                    <el-option
                      v-for="genre in selectors.entertainment.music.genres"
                      :key="genre.value"
                      :label="genre.label"
                      :value="genre.value"
                    />
                  </el-select>
                </div>
                <div class="section">
                  <h5 class="section-title">
                    Artists
                  </h5>
                  <el-select
                    v-model="selected.entertainment.music.artists"
                    filterable
                    multiple
                    placeholder="Select Artists"
                    style="width: 240px"
                  >
                    <el-option
                      v-for="artist in selectors.entertainment.music.artists"
                      :key="artist.value"
                      :label="artist.label"
                      :value="artist.value"
                    />
                  </el-select>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col
        :xs="24"
        :md="8"
        :lg="6"
      >
        <form>
          <div class="form-control">
            <label>Audience Name</label>
            <el-input v-model="title" />
          </div>
          <div class="form-control">
            <dedupe-control v-model="dedupe" />
          </div>
          <div class="form-control">
            <label>Organization</label>
            <org-picker :organization.sync="organization" />
          </div>
          <tag-picker :tags.sync="tags" />
          <div class="form-control">
            <demographic-selections
              :selections="selected"
              :political-ranges="politicalRanges"
            />
          </div>
          <div class="alert alert-count form-control">
            <p
              ref="deviceCountContainer"
              class="device-count"
            >
              0
            </p>
            <p>Total Devices</p>
          </div>
          <div class="form-control">
            <el-button
              v-if="isInternal && environment !== 'production'"
              type="primary"
              :disabled="noSelections || saving"
              :loading="queryPending"
              @click="getQuery"
            >
              Get Query
            </el-button>
          </div>
          <div class="form-control">
            <el-button
              type="info"
              :disabled="noSelections || saving || geographicSelectedCount < 1"
              :loading="countPending"
              @click="getCount"
            >
              Get Count
            </el-button>
          </div>
          <div class="form-control">
            <el-button
              type="primary"
              :disabled="!formReady"
              :loading="saving"
              @click="createAudience"
            >
              Create Audience
            </el-button>
          </div>
          <div class="form-control">
            <el-button
              :disabled="countPending || saving"
              @click="resetForm"
            >
              Reset
            </el-button>
          </div>
        </form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OrgPicker from '../global/OrgPicker';
import TagPicker from '../global/TagPicker';
import DemographicSelections from './DemographicSelections';
import { columnize } from '../../helpers';
import { CountUp } from 'countup.js';
import { audience } from '@/adonis-api';
import { getValidationErrors } from '@/helpers/validation-rules';
import DedupeControl from '../global/DedupeControl.vue';
import { mapGetters } from 'vuex';

import _flatten from 'lodash/flatten';
import _intersection from 'lodash/intersection';
import _union from 'lodash/union';
import _without from 'lodash/without';
import states from './data-mapping/states';
import HelpTip from '@/components/global/HelpTip.vue';

const CRRTLIMIT = 1000;
const ZIPLIMIT = 250;

export default {
  components: {
    DemographicSelections,
    DedupeControl,
    OrgPicker,
    TagPicker,
    HelpTip,
  },

  filters: {
    stateName(value) {
      const state = states.find(state => state.abbreviation === value);
      return state.name;
    },
  },

  data() {
    return {
      all: {
        charity: [
          false,
        ],
        demographic: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        ethnicities: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        financial: [
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        geographic: [
          false, // States
          false, // MSA
        ],
        interest: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        purchaseIntenders: [
          false,
          false,
          false,
          false,
          false,
        ],
        msa: [
          false,
        ],
        states: [
          false,
        ],
      },
      district: {
        state: null,
        cong_dist: null,
        st_up_hous: null,
        st_lo_hous: null,
      },
      dedupe: this.$store.getters['user/autoDedupe'],
      countPending: false,
      queryPending: false,
      saving: false,
      title: '',
      tags: [],
      loading: true,
      deviceCount: 0,
      organization: this.$store.state.user.orgDetails,
      politicalRanges: {
        mod_prty_aff_con_prb_mb: [0, 0],
        mod_prty_aff_dem_prb_mb: [0, 0],
        mod_prty_aff_grn_prb_mb: [0, 0],
        mod_prty_aff_ind_prb_mb: [0, 0],
        mod_prty_aff_lbt_prb_mb: [0, 0],
        mod_prty_aff_lib_prb_mb: [0, 0],
        mod_prty_aff_rep_prb_mb: [0, 0],
        mod_state_don_prb_mb: [0, 0],
        mod_state_don_amt_pct_mb: [0, 0],
        mod_state_don_amt_pred_mb: [0, 0],
        mod_fed_don_prb_mb: [0, 0],
        mod_fed_don_amt_pct_mb: [0, 0],
        mod_fed_don_amt_pred_mb: [0, 0],
        mod_to_mtpri_prb_mb: [0, 0],
        mod_to_mtgen_prb_mb: [0, 0],
        mod_to_ppri_prb_mb: [0, 0],
        mod_to_pgen_prb_mb: [0, 0],
      },
      selectors: {},
      selected: {}, // See resetForm() below
      postal_routes: null,
      zipcodes: null,
      crrt_limit_warning: false,
      zip_limit_warning: false,
    };
  },

  async beforeRouteLeave(to, from, next) {
    if (this.countPending) {
      try {
        await this.$confirm(
          'If you leave this page, the count in progress will be abandoned. Are you sure?',
          'Count Pending',
        );
        next();
      } catch (e) {
        next(false);
      }
      return;
    }

    next();
  },

  computed: {
    ...mapGetters('app', ['environment']),
    ...mapGetters('user', ['hasPermission', 'isInternal']),

    noSelections() {
      if (
        this.selected.charity.length ||
        this.selected.demographic.length ||
        this.selected.financial.length ||
        this.selected.interest.length ||
        this.selected.ethnicities.length ||
        this.geographicSelectedCount ||
        this.selected.political.length ||
        this.selected.politicalDistrict.length ||
        this.selected.purchaseIntenders.length ||
        this.selected.entertainment.music.artists.length ||
        this.selected.entertainment.music.genres.length
      ) {
        return false;
      }
      return true;
    },
    filteredCharities() {
      return this.selectors.charities.filter(c => {
        return !c.omit;
      });
    },
    formReady() {
      if (
        this.title &&
        this.deviceCount &&
        (this.organization && this.organization.id) &&
        !this.noSelections &&
        !this.countPending &&
        (this.geographicSelectedCount > 0)
      ) {
        return true;
      }
      return false;
    },
    columnizedInterests() {
      return columnize(this.selectors.interests[2].children, 3);
    },
    geographicSelectedCount() {
      return (
        this.selected.geographic.states.length +
        this.selected.geographic.msa.length +
        this.selected.geographic.postal_routes.length +
        this.selected.geographic.zipcodes.length
      );
    },
  },

  watch: {
    title(val) {
      if (val) {
        this.title = val.replace(/[^0-9a-z. -]/gi, '-');
      }
    },
    deviceCount(newValue, oldValue) {
      if (newValue !== oldValue) {
        const numberAnimation = new CountUp(
          this.$refs.deviceCountContainer,
          newValue,
          {
            startVal: oldValue,
            duration: 1,
          },
        );
        numberAnimation.start();
      }
    },
    postal_routes(val) {
      if (val) {
        const routes = val.match(/\d{5}[bchrBCHR]\d{3}/g);
        if (Array.isArray(routes)) {
          this.selected.geographic.postal_routes = routes.map(route => route.toUpperCase());
          if (this.selected.geographic.postal_routes.length > CRRTLIMIT) {
            this.selected.geographic.postal_routes = this.selected.geographic.postal_routes.slice(0, CRRTLIMIT);
            if (!this.crrt_limit_warning) {
              this.$notify.warning(`You have exceeded the maximum allowed ${CRRTLIMIT.toLocaleString()} Postal Carrier Routes. Excess items will be omitted.`);
              this.crrt_limit_warning = true;
            }
          }
        } else {
          this.selected.geographic.postal_routes = [];
        }
      } else {
        this.selected.geographic.postal_routes = [];
      }
    },
    zipcodes(val) {
      if (val) {
        const zips = val.match(/\d{5}/g);
        if (Array.isArray(zips)) {
          this.selected.geographic.zipcodes = zips;
          if (this.selected.geographic.postal_routes.length > ZIPLIMIT) {
            this.selected.geographic.zipcodes = this.selected.geographic.zipcodes.slice(0, ZIPLIMIT);
            if (!this.zip_limit_warning) {
              this.$notify.warning(`You have exceeded the maximum allowed ${ZIPLIMIT.toLocaleString()} Zip Codes. Excess items will be omitted.`);
            }
          }
        } else {
          this.selected.geographic.zipcodes = [];
        }
      } else {
        this.selected.geographic.zipcodes = [];
      }
    },
  },

  async created() {
    this.resetForm();
    this.selectors = await audience.getDemographicSelectors();
    this.selectors.msa = columnize(this.selectors.msa, 3);
    this.selectors.states = columnize(this.selectors.states, 3);
    this.loading = false;
  },

  methods: {
    addDistrict() {
      this.selected.politicalDistrict.push(this.district);
      this.district = {
        state: null,
        cong_dist: null,
        st_lo_hous: null,
        st_up_hous: null,
      };
      this.resetCount();
    },

    async createAudience() {
      this.saving = true;

      try {
        const result = await audience.createDemographicAudience({
          name: this.title.trim(),
          count: this.deviceCount,
          tags: this.tags,
          organization_id: this.organization.id,
          selections: this.selected,
          political_ranges: this.politicalRanges,
          auto_dedupe: this.dedupe,
        });

        window.mixpanel.track('Create Demographic Audience', {
          ID: result.data.id,
          Name: result.data.name,
          Organization: this.organization.name,
          Count: this.deviceCount,
        });
        this.$message.success({
          message: `Demographic Audience "${result.data.name}" saved.`,
        });

        this.resetForm();
        this.saving = false;
      } catch (e) {
        this.saving = false;
        const errors = getValidationErrors(e);

        if (errors.name) {
          this.$notify({
            message: getValidationErrors(e).name,
            title: 'Error',
            type: 'error',
          });
        } else {
          this.$reportError(e);
        }
      }
    },

    async getCount() {
      this.countPending = true;

      try {
        const result = await audience.getDemographicsCount({
          selections: this.selected,
          political_ranges: this.politicalRanges,
        });

        // set results to this.deviceCount
        this.deviceCount = result.data.count;
      } catch (e) {
        this.$reportError(e);
        this.$notify({
          message: 'A problem occurred while requesting a count for your selections.',
          title: 'Error',
          type: 'error',
        });
      } finally {
        this.countPending = false;
      }
    },

    getDistrictRange(districtKey, state) {
      const district = this.selectors.politicalDistricts.find(pd => pd.abbreviation === state);

      if (district[`${districtKey}_max`]) {
        return [...Array(district[`${districtKey}_max`]).keys()].map(i => i + 1);
      }

      return [];
    },

    async getQuery() {
      this.queryPending = true;

      const result = await audience.getDemographicsQuery({
        selections: this.selected,
        political_ranges: this.politicalRanges,
      });

      this.queryPending = false;

      this.$alert(result.query, 'Query');
    },

    handleCheckAllChange(section, segment, checked) {
      let items;
      switch (section) {
        case 'charity':
          items = this.selectors.charities.map(i => i.value);
          break;
        case 'demographic':
          items = this.selectors.demographicSegments[segment].children.map(i => i.value);
          break;
        case 'ethnicities':
          items = this.selectors.ethnicities[segment].children.map(i => i.value);
          break;
        case 'financial':
          items = this.selectors.financials[segment].children.map(i => i.value);
          break;
        case 'interest':
          items = this.selectors.interests[segment].children.map(i => i.value);
          break;
        case 'states':
          items = _flatten(this.selectors.states).map(i => i.abbreviation);
          break;
        case 'msa':
          items = _flatten(this.selectors.msa).map(i => i.value);
          break;
        case 'purchaseIntenders':
          items = this.selectors.purchaseIntenders[segment].children.map(i => i.value);
          break;
        default:
          break;
      }

      if (section === 'states' || section === 'msa') {
        if (checked) {
          this.selected.geographic[section] = _union(this.selected.geographic[section], items);
        } else {
          this.selected.geographic[section] = _without(this.selected.geographic[section], ...items);
        }
      } else {
        if (checked) {
          this.selected[section] = _union(this.selected[section], items);
        } else {
          this.selected[section] = _without(this.selected[section], ...items) || [];
        }
      }
    },

    indeterminate(section, segment) {
      let items;
      switch (section) {
        case 'charity':
          items = this.selectors.charities.map(i => i.value);
          break;
        case 'demographic':
          items = this.selectors.demographicSegments[segment].children.map(i => i.value);
          break;
        case 'ethnicities':
          items = this.selectors.ethnicities[segment].children.map(i => i.value);
          break;
        case 'financial':
          items = this.selectors.financials[segment].children.map(i => i.value);
          break;
        case 'interest':
          items = this.selectors.interests[segment].children.map(i => i.value);
          break;
        case 'states':
          items = _flatten(this.selectors.states).map(i => i.abbreviation);
          break;
        case 'msa':
          items = _flatten(this.selectors.msa).map(i => i.value);
          break;
        case 'purchaseIntenders':
          items = this.selectors.purchaseIntenders[segment].children.map(i => i.value);
          break;
        default:
          break;
      }
      let intersection;
      if (section === 'states' || section === 'msa') {
        intersection = _intersection(this.selected.geographic[section], items);
      } else {
        intersection = _intersection(this.selected[section], items);
      }

      if (intersection.length === items.length) {
        this.all[section][segment] = true;
      } else if (!intersection.length) {
        this.all[section][segment] = false;
      }

      return !!(intersection.length > 0 && intersection.length !== items.length);
    },

    removeDistrict(index) {
      this.selected.politicalDistrict.splice(index, 1);
      this.resetCount();
    },

    resetCount() {
      this.deviceCount = 0;
    },

    resetForm() {
      this.deviceCount = 0;
      this.countPending = false;
      this.title = '';
      this.tags = [];
      this.organization = this.$store.state.user.orgDetails;
      this.selected = {
        charity: [],
        demographic: [],
        ethnicities: [],
        financial: [],
        geographic: {
          states: [],
          msa: [],
          postal_routes: [],
          zipcodes: [],
        },
        interest: [],
        political: [],
        politicalDistrict: [],
        purchaseIntenders: [],
        entertainment: {
          music: {
            artists: [],
            genres: [],
          },
        },
      };
      this.zipcodes = null;
      this.politicalRanges = {
        mod_prty_aff_con_prb_mb: [0, 0],
        mod_prty_aff_dem_prb_mb: [0, 0],
        mod_prty_aff_grn_prb_mb: [0, 0],
        mod_prty_aff_ind_prb_mb: [0, 0],
        mod_prty_aff_lbt_prb_mb: [0, 0],
        mod_prty_aff_lib_prb_mb: [0, 0],
        mod_prty_aff_rep_prb_mb: [0, 0],
        mod_state_don_prb_mb: [0, 0],
        mod_state_don_amt_pct_mb: [0, 0],
        mod_state_don_amt_pred_mb: [0, 0],
        mod_fed_don_prb_mb: [0, 0],
        mod_fed_don_amt_pct_mb: [0, 0],
        mod_fed_don_amt_pred_mb: [0, 0],
        mod_to_mtpri_prb_mb: [0, 0],
        mod_to_mtgen_prb_mb: [0, 0],
        mod_to_ppri_prb_mb: [0, 0],
        mod_to_pgen_prb_mb: [0, 0],
      };
    },

    stateLimit(state) {
      return this.selected.geographic.states.length === 5 &&
      !this.selected.geographic.states.includes(state);
    },

    msaLimit(msa) {
      return this.selected.geographic.msa.length === 20 &&
      !this.selected.geographic.msa.includes(msa);
    },
  },
};

</script>

<style lang="scss" scoped>
.section {
  float: left;
  margin-right: 50px;
  &.political {
    min-height: 400px;
  }
}

.section-title {
  color: #8391a5;
  border-bottom: 1px solid #d1dbe5;
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

:deep( .el-badge__content) {
  background-color: #8492a6;
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  border-radius: 5px;
  padding: 0 4px;
  &.is-fixed {
    top: 8px;
    right: 5px;
  }
}

form {
  margin-top: 1.25rem;
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-input,
  .el-select,
  .el-button {
    width: 100%;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
}

.no-pad {
  .el-tabs__nav {
    .el-tabs__item {
      padding-left: 0;
    }
  }
}

.select-all {
  float: left;
  margin-right: 10px !important;
}

.alert-count {
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: #ccc;
  border-color: #ddd;
  background-color: #eee;
  .count {
    margin: 0.5rem 0 0;
    font-size: 2rem;
    font-weight: 800;
    color: #bbb;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  }
}

.political {
  :deep( .el-checkbox__label) {
    width: 300px !important;
  }
  .el-slider {
    position: relative;
    top: 14px;
    display: inline-block;
    width: 225px;
  }
}

.el-table.political-districts {
  width: 100% !important;
  max-width: 1000px;
  .el-table__header {
    width: 100% !important;
  }
}
</style>
